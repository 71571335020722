
import { FilterItem } from "@/services/types";
import { defineComponent, onMounted, PropType, ref } from "vue";
import ArrowDown from "../svg/ArrowDown.vue";

export default defineComponent({
  components: {
    ArrowDown
  },
  props: {
    data: {
      type: Array as PropType<FilterItem[]>,
      default: []
    },
    defaultValue: Object
  },
  setup(props, { emit }) {
    const current = ref(
      props.defaultValue
        ? props.defaultValue
        : props.data.length > 0
        ? props.data[0]
        : { label: "---" }
    );

    const isOpened = ref(false);
    const dropdownRef = ref();

    onMounted(() => {
      emit("change", current.value);
      document.addEventListener("click", event => {
        if (
          dropdownRef &&
          dropdownRef.value &&
          !dropdownRef.value.contains(event.target)
        ) {
          isOpened.value = false;
        }
      });
    });

    const toggle = () => {
      isOpened.value = !isOpened.value;
    };

    const handleChange = (item: any) => {
      emit("change", item);
      current.value = item;
    };

    return {
      current,
      isOpened,
      items: props.data,
      toggle,
      dropdownRef,
      handleChange
    };
  }
});
