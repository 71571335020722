
import { defineComponent, onMounted, ref, watch } from "vue";
import Close from "../svg/Close.vue";

export default defineComponent({
  components: {
    Close
  },
  props: {
    open: Boolean
  },
  setup(props, { emit }) {
    const isOpened = ref(props.open);

    const close = () => {
      isOpened.value = false;
      emit("closed");
    };

    onMounted(() => {
      window.addEventListener("keyup", onKeyUp);
    })

    function onKeyUp(e: KeyboardEvent) {
      if (e.key === "Escape") {
        close();
    }
    }

    watch(
      props,
      newProps => {
        console.log("new open value", newProps.open);
        isOpened.value = newProps.open;
      },
      { deep: true }
    );

    return {
      isOpened,
      close,
      onKeyUp
    };
  }
});
