import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "fixed top-0 left-0 w-full h-full z-10 bg-black-fade" }
const _hoisted_2 = { class: "fixed right-0 top-0 h-full w-full z-20 md:w-2/5 p-24px bg-white cursor-pointer overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close = _resolveComponent("close")!

  return (_ctx.isOpened)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onKeyUp && _ctx.onKeyUp(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "w-full h-full",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, [
              _createVNode(_component_close)
            ]),
            _renderSlot(_ctx.$slots, "default")
          ])
        ])
      ], 32))
    : _createCommentVNode("", true)
}