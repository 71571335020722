
import router from "@/router";
import { defineComponent, PropType, reactive, watch } from "vue";
import { ProductItemType } from "../../services/types";
import ProductItem from "./ProductItem.vue";

export default defineComponent({
  components: { ProductItem },
  props: {
    data: Array as PropType<ProductItemType[]>
  },
  setup(props, { emit }) {
    const state = reactive({
      items: props.data
    });

    watch(
      () => props.data,
      () => (state.items = props.data)
    );

    const onSelected = (id: number) => {
      // emit("selected", item);
      router.push("/" + id);
    };

    return {
      state,
      onSelected
    };
  }
});
