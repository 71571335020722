import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d631617c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fit-content" }
const _hoisted_2 = { class: "bg-light px-3 py-2 flex gap-3 rounded-xl" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(
          item.value === _ctx.current.value
            ? 'bg-white text-black font-bold px-4 py-3 rounded-xl cursor-pointer activeFilter'
            : 'bg-transparent text-dark px-4 py-3 rounded-xl cursor-pointer'
        ),
          onClick: ($event: any) => (_ctx.handleChange(item))
        }, _toDisplayString(item.label), 11, _hoisted_3))
      }), 128))
    ])
  ]))
}