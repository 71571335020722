import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-257befee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-section" }
const _hoisted_2 = { class: "hidden md:flex md:justify-center" }
const _hoisted_3 = { class: "md:hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Switch = _resolveComponent("Switch")!
  const _component_dropdown = _resolveComponent("dropdown")!
  const _component_product_list = _resolveComponent("product-list")!
  const _component_container = _resolveComponent("container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Switch, {
        data: _ctx.filterItems,
        onChange: _ctx.handleChange
      }, null, 8, ["data", "onChange"])
    ]),
    _createVNode(_component_container, { style: {"max-width":"1200px"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_dropdown, {
            data: _ctx.filterItems,
            onChange: _ctx.handleChange
          }, null, 8, ["data", "onChange"])
        ]),
        _createVNode(_component_product_list, {
          data: _ctx.state.itemToDisplay,
          onSelected: _ctx.seeDetails
        }, null, 8, ["data", "onSelected"])
      ]),
      _: 1
    })
  ]))
}