import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ac96f02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative product-box mt-10" }
const _hoisted_2 = { class: "absolute bottom-0 py-3 text-center bg-white brand" }
const _hoisted_3 = { class: "text-gray font-size-20" }
const _hoisted_4 = {
  key: 0,
  class: "text-black font-semibold"
}
const _hoisted_5 = {
  key: 1,
  class: "text-black font-semibold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "imgContainer",
      style: _normalizeStyle('background-image: url(img/products/' + _ctx.item.urls[0] + ')')
    }, null, 4),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.title), 1),
      (_ctx.item.discounted)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.item.discountText), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.item.amount) + " Fcfa", 1))
    ])
  ]))
}