
import { ProductItemType } from "@/services/types";
import { defineComponent, onMounted, PropType } from "vue";
import Facebook from "../svg/Facebook.vue";
import Twitter from "../svg/Twitter.vue";
import Whatsapp from "../svg/Whatsapp.vue";

export default defineComponent({
  components: {
    Facebook,
    Twitter,
    Whatsapp
  },
  props: {
    data: Object as PropType<ProductItemType>,
    productIndex: Number
  },
  setup(props) {
    onMounted(() => {
      console.log("props.data:", props.data);
    });

    function generateBackgroundUrl(url: string) {
      return `url(${"/img/products/" + url})`;
    }

    function generateWhatsappUrl() {

      return (
        "whatsapp://send?text=" +
        process.env.VUE_APP_LINK +
        "/" +
        props.productIndex
      );
    }

    function generateTwitterUrl() {

      return (
        "https://twitter.com/intent/tweet?text=" +
        process.env.VUE_APP_LINK +
        "/" +
        props.productIndex
      );
    }

    function generateFacebookUrl() {

      return (
        "http://www.facebook.com/share.php?=u=" +
        process.env.VUE_APP_LINK +
        "/" +
        props.productIndex
      );
    }

    return {
      item: props.data,
      generateBackgroundUrl,
      generateWhatsappUrl,
      generateTwitterUrl,
      generateFacebookUrl
    };
  }
});
