import { ProductItemType } from "./types";

export const PRODUCTS_: ProductItemType[] = [
  {
    id: 1,
    urls: ["edi-sessi/1-sessi.png", "edi-sessi/2-sessi.png"],
    title: "Ensemble Sheyi blanc",
    amount: "100 000",
    description:
      "Un ensemble blanc adapté pour vos différentes sorties professionnelles et classes",
    owner: "Edi Sessi",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/3986/ensemble-sheyi-st-valentin-"
  },
  {
    id: 2,
    urls: ["palma/2-palma.png", "palma/1-palma.jpeg", "palma/3-palma.jpeg"],
    title: "Liqueur 75cl + Cristaux de menthe",
    amount: "5 000",
    description:
      "Palma liqueur Gingembre et ahowé 75 cl + 2 boîte de cristaux de menthe",
    owner: "Palma liqueur",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/5916/coffret-nuit-torride-palma-gf"
  },
  {
    id: 3,
    urls: [
      "chic-crea/1-chic-crea.png",
      "chic-crea/3-chic-crea.jpg",
      "chic-crea/4-chic-crea.jpg"
    ],
    title: "Coffret Tchiza",
    amount: "20 000",
    description:
      "Coffret composé d' un parfum des bracelet et un gel de massage",
    owner: "Chic Créa",
    facebookUrl: "https://www.facebook.com/chiccrea/",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/1916/coffret-tchiza-1"
  },
  {
    id: 4,
    urls: [
      "chic-crea/2-chic-crea.png",
      "chic-crea/3-chic-crea.jpg",
      "chic-crea/4-chic-crea.jpg"
    ],
    title: "Coffret Titulaire",
    amount: "20 000",
    description: "Coffret composé d'un parfum des brecelet ",
    owner: "Chic Créa",
    facebookUrl: "https://www.facebook.com/chiccrea/",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/1916/coffret-titulaire-1"
  },
  {
    id: 5,
    urls: [
      "katoo/1-katoo.png",
      "katoo/2-katoo.png",
      "katoo/3-katoo.webp",
      "katoo/4-katoo.webp"
    ],
    title: "Vêtements Femme et Homme",
    amount: "",
    description: "",
    owner: "Katoo",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://katoo.afrikrea.com/",
    discounted: true,
    discountText: "10 à 30% de réduction"
  },
  {
    id: 19,
    urls: ["katoo/5-katoo.png"],
    title: "Robe rouge milady ",
    amount: "55 000",
    description: "Robe mi longue rouge",
    owner: "Katoo",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/5975/robe-milady-"
  },
  {
    id: 12,
    urls: ["michelle/2-michelle.png", "michelle/3-michelle.jpg"],
    title: "Sac Collection Natu'relle",
    amount: "45 000",
    description: "Un Sac simple, pratique et double utilisation",
    owner: "Michelle Deguenon",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/5995/sac-collection-natu'relle"
  },

  {
    id: 11,
    urls: ["michelle/1-michelle.jpg", "michelle/4-michelle.jpeg"],
    title: "Slipper Collection Natu'relle",
    amount: "25 000",
    description:
      "Adapté pour le bureau, les sorties Professionnelles et détentes",
    owner: "Michelle Deguenon",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/5995/slipper-collection-natu'relle"
  },
  // {
  //   id: 6,
  //   urls: ["katoo/2-katoo.png"],
  //   title: "Costumes Homme",
  //   amount: "",
  //   description: "",
  //   owner: "Katoo",
  //   facebookUrl: "#",
  //   twitterUrl: "#",
  //   whatsappUrl: "#",
  //   buyUrl: "https://katoo.afrikrea.com/",
  //   discounted: true,
  //   discountText: "20% de réduction"
  // },
  // {
  //   id: 7,
  //   urls: [
  //     "sweet-precious/2-sweet-precious.png",
  //     "sweet-precious/3-sweet-precious.jpg",
  //     "sweet-precious/1-sweet-precious.jpg",
  //     "sweet-precious/4-sweet-precious.jpg",
  //     "sweet-precious/5-sweet-precious.jpeg"
  //   ],
  //   title: "Coffret de fleur et chocolat",
  //   amount: "50 000",
  //   description: "Un coffret composé de fleur naturelle et de chocolat",
  //   owner: "Sweet Precious",
  //   facebookUrl: "#",
  //   twitterUrl: "#",
  //   whatsappUrl: "#",
  //   buyUrl: "#"
  // },
  // {
  //   id: 8,
  //   urls: [
  //     "sweet-precious/3-sweet-precious.png",
  //     "sweet-precious/1-sweet-precious.jpg",
  //     "sweet-precious/5-sweet-precious.jpeg"
  //   ],
  //   title: "Coffret de chocolat",
  //   amount: "20 000",
  //   description: "Un coffret composé uniquement de chocolat",
  //   owner: "Sweet Precious",
  //   facebookUrl: "#",
  //   twitterUrl: "#",
  //   whatsappUrl: "#",
  //   buyUrl: "#"
  // },
  {
    id: 10,
    urls: ["csk/2-csk.jpg"],
    title: "Perruque deep wave",
    amount: "240 000",
    description: "",
    owner: "CSK",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/1910/deep-waver-couleur"
  },
  {
    id: 9,
    urls: ["csk/3-csk.png", "csk/1-csk.jpg"],
    title: "Perruque curly",
    amount: "135 000",
    description: "Perruque deep wave",
    owner: "CSK",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/1910/curly"
  },

  {
    id: 13,
    urls: ["abisik/1-abisik.png", "abisik/2-abisik.jpg"],
    title: "Nuisette en soie",
    amount: "40 000",
    description: "Un coffret de lingerie",
    owner: "ABISIK",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://abisik.com/produit/lnm007/"
  },
  {
    id: 14,
    urls: ["abisik/3-abisik.jpg", "abisik/4-abisik.jpg"],
    title: "Coffret BDSM",
    amount: "60 000",
    description: "Un coffret BDSM composé de différents accessoires",
    owner: "ABISIK",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://abisik.com/produit/ssm069-kit-bdsm/"
  },
  {
    id: 15,
    urls: [
      "assana-food/3-assana-food.jpeg",
      "assana-food/1-assana-food.jpg",
      "assana-food/2-assana-food.jpeg",
      "assana-food/4-assana-food.jpeg",
      "assana-food/5-assana-food.jpeg"
    ],
    title: "Gourmandises Pack St Valentin",
    amount: "10 000",
    description:
      "Un coffret commposé de petites gourmandises à déguster avec votre partenaire",
    owner: "Assana Food",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/3524/pack-st-valentin-1-1-1"
  },
  {
    id: 16,
    urls: [
      "maison-rouge/1-maison-rouge.png",
      "maison-rouge/2-maison-rouge.png",
      "maison-rouge/3-maison-rouge.png"
    ],
    title: "Chambre classique pour 2 pers",
    amount: "96 000",
    description: "Une chambre classique pour deux",
    owner: "Maison Rouge",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/2091/chambre-classique-pour-2-pers"
  },
  {
    id: 17,
    urls: [
      "maison-rouge/6-maison-rouge.png",
      "maison-rouge/4-maison-rouge.png",
      "maison-rouge/5-maison-rouge.png"
    ],
    title: "Chambre supérieure pour 2 pers",
    amount: "116 000",
    description: "Une chambre supérieure pour deux",
    owner: "Maison Rouge",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/2091/chambre-superieure-pour-2-pers"
  },
  {
    id: 18,
    urls: ["gounou/1-gounou.png", "gounou/2-gounou.png"],
    title: "Coffret Love with gounou",
    amount: "20 000",
    description: "",
    owner: "Gounou",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://www.gounouchocolat.com/luxury/details"
  },
  {
    id: 20,
    urls: ["liende/1-liende.png"],
    title: "Chaussure fermée homme",
    amount: "40 000",
    description: "",
    owner: "Gounou",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/3728/chaussure-fermee"
  },
  {
    id: 21,
    urls: ["liende/2-liende.png"],
    title: "Chaussure fermée homme motif africain",
    amount: "40 000",
    description: "",
    owner: "Liende",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/3728/chaussure-fermee-1"
  },
  {
    id: 22,
    urls: ["omami/1-omami.png", "omami/3-omami.png", "omami/4-omami.png"],
    title: "Coffret Mi Amore",
    amount: "35 000",
    description:
      "Composé de Love en donuts, bouteille de vin a basse d'ananas, boîte de macarons, Gommage bio, lotion pour le corps, boîte de chocolat en forme de coeur confiture de mangue orange baobab, gel de douche corp visage",
    owner: "Omami",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/1489/coffret-mi-amore-"
  },
  {
    id: 23,
    urls: ["omami/2-omami.png", "omami/5-omami.png", "omami/6-omami.png"],
    title: "Coffret Cupidon",
    amount: "20 000",
    description:
      "Composé de Love en donuts, bouteille de vin a basse d'ananas, boîte de macarons, Gommage bio, lotion pour le corps, boîte de chocolat en forme de coeur confiture de mangue orange baobab, gel de douche corp visage",
    owner: "Omami",
    facebookUrl: "#",
    twitterUrl: "#",
    whatsappUrl: "#",
    buyUrl: "https://direct.kkiapay.me/1489/coffret-cupidon-1"
  }
];
