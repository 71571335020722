
import { defineComponent, onMounted, reactive, ref } from "vue";
import Container from "../utils/Container.vue";
import Switch from "../utils/Switch.vue";
import ProductList from "./ProductList.vue";
import { PRODUCTS_ } from "../../services/faker";
import Dropdown from "../utils/Dropdown.vue";
import { FilterItem, ProductItemType } from "@/services/types";
import Modal from "../utils/Modal.vue";
import ProductDisplay from "../section/ProductDisplay.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    Switch,
    Container,
    ProductList,
    Dropdown,
    Modal,
    ProductDisplay
  },
  setup(_props, context) {
    const { t } = useI18n();

    const state = reactive({
      isModalOpened: false,
      selectedItem: null as null | ProductItemType,
      currentFilter: null as null | FilterItem,
      itemToDisplay: [] as ProductItemType[]
    });

    const filterItems: FilterItem[] = [
      { value: "default", label: t("defaultOrder") },
      { value: "per-most-expensive", label: t("byMostExpensive") },
      { value: "per-less-expensive", label: t("byLessExpensive") },
      { value: "discounted", label: t("discounted") }
    ];

    onMounted(() => {
      console.log("--------context:", context);
    });

    const seeDetails = (value: ProductItemType) => {
      console.log("-------value:", value);
      // selectedItem.value = value;
      context.emit("openModal", value);
    };

    const closeModal = () => {
      state.isModalOpened = false;
    };

    const handleChange = (choosenFilter: FilterItem) => {
      state.currentFilter = choosenFilter;
      filterListItems();
    };

    onMounted(() => {
      filterListItems();
    });

    function filterListItems() {
      if (
        state.currentFilter === null ||
        state.currentFilter.value === "default"
      ) {
        state.itemToDisplay = [...PRODUCTS_];
        return;
      }

      switch (state.currentFilter.value) {
        case "per-most-expensive":
          state.itemToDisplay = [...PRODUCTS_]
            .filter(el => el.discounted !== true)
            .map(ele => {
              return {
                ...ele,
                intAmount: parseInt(ele.amount.replaceAll(" ", ""))
              };
            })
            .sort((b, a) => compareTwoValue(a.intAmount, b.intAmount));
          break;

        case "per-less-expensive":
          state.itemToDisplay = [...PRODUCTS_]
            .filter(el => el.discounted !== true)
            .map(ele => {
              return {
                ...ele,
                intAmount: parseInt(ele.amount.replaceAll(" ", ""))
              };
            })
            .sort((a, b) => compareTwoValue(a.intAmount, b.intAmount));
          break;

        case "discounted":
          state.itemToDisplay = [...PRODUCTS_]
            .filter(el => el.discounted === true)
            .map(ele => {
              return {
                ...ele,
                intAmount: parseInt(ele.amount.replaceAll(" ", ""))
              };
            });
          break;
      }

      console.log("state.itemToDisplay.length:", state.itemToDisplay.length);

      console.log("state.itemToDisplay:", state.itemToDisplay);
    }

    function compareTwoValue(a: number, b: number) {
      if (a < b) return -1;
      if (a > b) return 1;
      // a doit être égal à b
      return 0;
    }

    return {
      items: PRODUCTS_,
      handleChange,
      seeDetails,
      closeModal,
      state,
      filterItems
    };
  }
});
