<template>
  <svg
    width="16"
    height="9"
    viewBox="0 0 16 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.6875 1.7285L1.93364 0.5L8 6.494L14.0801 0.5L15.3125 1.715L8.61622 8.3165L8 8.9375L7.38378 8.3165L0.6875 1.7285Z"
      fill="black"
    />
  </svg>
</template>