import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center py-4" }
const _hoisted_2 = { class: "flex md:flex md:items-center gap-2" }
const _hoisted_3 = {
  href: "https://www.facebook.com/kkiapay.me/",
  target: "_blank"
}
const _hoisted_4 = {
  href: "https://twitter.com/kkiapay",
  target: "_blank"
}
const _hoisted_5 = {
  href: "https://wa.me/message/TEEBJFAGMPUBH1",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_kkiapay = _resolveComponent("kkiapay")!
  const _component_facebook = _resolveComponent("facebook")!
  const _component_twitter = _resolveComponent("twitter")!
  const _component_whatsapp = _resolveComponent("whatsapp")!
  const _component_container = _resolveComponent("container")!

  return (_openBlock(), _createBlock(_component_container, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_kkiapay, { class: "kkiapay-logo" })
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("a", _hoisted_3, [
            _createVNode(_component_facebook)
          ]),
          _createElementVNode("a", _hoisted_4, [
            _createVNode(_component_twitter)
          ]),
          _createElementVNode("a", _hoisted_5, [
            _createVNode(_component_whatsapp)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}