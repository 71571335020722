
import { defineComponent, onMounted, ref, watch } from "vue";
import Header from "../components/header/Header.vue";
import Footer from "../components/footer/Footer.vue";
import Hero from "../components/hero/Hero.vue";
import ProductSection from "../components/section/ProductSection.vue";
import ProductDisplay from "../components/section/ProductDisplay.vue";
import { ProductItemType } from "@/services/types";
import Modal from "../components/utils/Modal.vue";
import { PRODUCTS_ } from "@/services/faker";
import router from "@/router";

export default defineComponent({
  components: {
    Header,
    Footer,
    Hero,
    ProductSection,
    ProductDisplay,
    Modal
  },
  setup() {
    const isModalOpened = ref(false);
    const selectedItem = ref({});
    const selectedItemIndex = ref();

    const closeModal = () => {
      isModalOpened.value = false;
      router.push("/");
    };

    function onOpenModal(theItem: ProductItemType) {
      selectedItem.value = theItem;
      isModalOpened.value = true;
    }

    onMounted(() => {
      console.log(
        "router.currentRoute.value.params:",
        router.currentRoute.value.params
      );
      findItemForProductId();
    });

    watch(
      () => router.currentRoute.value.params.id,
      () => {
        selectedItemIndex.value = router.currentRoute.value.params.id;
        findItemForProductId();
      }
    );

    function findItemForProductId() {
      if (router.currentRoute.value.params.id) {
        const productId = parseInt(
          router.currentRoute.value.params.id as string
        );
        if (productId !== null) {
          const theProduct = PRODUCTS_.find(prod => prod.id === productId);

          if (theProduct) {
            selectedItem.value = theProduct;
            isModalOpened.value = true;
          }
        } else {
          router.push("/");
        }
      }
    }

    return {
      isModalOpened,
      selectedItem,
      selectedItemIndex,
      onOpenModal,
      closeModal
    };
  }
});
