import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-547cadec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = { class: "font-size-14 font-bold mb-1" }
const _hoisted_3 = { class: "currentItemDisplayer bg-light text-dark flex items-center justify-between py-2 px-3 gap-10 rounded-lg" }
const _hoisted_4 = {
  key: 0,
  class: "itemListDisplayer absolute top-100 mt-1 dropdown rounded-lg bg-white"
}
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_down = _resolveComponent("arrow-down")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("filterBy")) + " :", 1),
    _createElementVNode("div", {
      class: "relative",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
      ref: "dropdownRef"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, _toDisplayString(_ctx.current.label), 1),
        _createVNode(_component_arrow_down)
      ]),
      (_ctx.isOpened)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "p-2 hover:bg-light hover:text-dark hover:rounded-lg cursor-pointer",
                onClick: ($event: any) => (_ctx.handleChange(item))
              }, _toDisplayString(item.label), 9, _hoisted_5))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 512)
  ]))
}