
import { defineComponent } from "vue";
import Kkiapay from "../svg/Kkiapay.vue";
import Facebook from "../svg/Facebook.vue";
import Twitter from "../svg/Twitter.vue";
import Whatsapp from "../svg/Whatsapp.vue";
import Share from "../svg/Share.vue";
import Container from "../utils/Container.vue";

export default defineComponent({
  components: {
    Kkiapay,
    Facebook,
    Twitter,
    Whatsapp,
    Share,
    Container
  }
});
