<template>
  <svg
    width="42"
    height="42"
    viewBox="-2 -2 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#00BFF3" />
    <rect
      width="14.7692"
      height="14.7692"
      transform="translate(8.61523 8.61539)"
      fill="#00BFF3"
    />
    <path
      d="M13.7168 21.0014C18.3615 21.0014 20.9014 17.1534 20.9014 13.8168C20.9014 13.7074 20.8992 13.5987 20.8942 13.4903C21.3872 13.1339 21.8158 12.6891 22.1539 12.1829C21.7014 12.3839 21.2146 12.5193 20.7038 12.5803C21.2251 12.2677 21.6254 11.773 21.8143 11.1834C21.3263 11.4727 20.786 11.683 20.2109 11.7964C19.75 11.3056 19.0937 10.9986 18.3676 10.9986C16.973 10.9986 15.8421 12.1295 15.8421 13.5235C15.8421 13.7217 15.8643 13.9143 15.9074 14.0992C13.809 13.9938 11.948 12.9889 10.7027 11.4611C10.4856 11.8342 10.3606 12.2678 10.3606 12.7302C10.3606 13.6062 10.8065 14.3796 11.4844 14.8322C11.0702 14.8194 10.681 14.7055 10.3407 14.5162C10.3402 14.5267 10.3402 14.5374 10.3402 14.5484C10.3402 15.7715 11.2107 16.7925 12.366 17.0239C12.1539 17.0817 11.9308 17.1128 11.7004 17.1128C11.5377 17.1128 11.3796 17.0965 11.2257 17.0673C11.5471 18.0704 12.4794 18.8005 13.5847 18.8211C12.7203 19.4985 11.6315 19.9021 10.4485 19.9021C10.2449 19.9021 10.0438 19.8905 9.84619 19.8671C10.9637 20.5832 12.2906 21.0014 13.7168 21.0014Z"
      fill="white"
    />
  </svg>
</template>
