
import { defineComponent } from "vue";
import Container from "../utils/Container.vue";
import Kkiapay from "../svg/Kkiapay.vue";

export default defineComponent({
  components: {
    Container,
    Kkiapay
  }
});
