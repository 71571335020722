<template>
  <div class="hero flex justify-center items-end pb-40 relative">
    <div
      class="hero-text-block flex justify-center items-center flex-col gap-3"
    >
      <div
        class="hero-title text-center text-white font-bold font-size-50 w-full"
      >
        {{ $t("bestGift") }}<br />
        {{ $t("bestGiftToTouch") }}
      </div>
      <div
        class="hero-subtitle text-center text-white font-semibold font-size-16 w-full md:w-2/3"
      >
        {{ $t("findGift") }} <b /> {{ $t("byKkiapayTeam") }}
      </div>
    </div>

    <div class="round absolute bottom-0 w-full left-0"></div>
    <div class="absolute w-full bg-white bottom-0 round-associate"></div>
  </div>
</template>

<style lang="scss" scoped>
.hero {
  background: url("../../assets/bgd1.webp"), url("../../assets/bgd2.webp");
  background-color: #ef2661;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .hero-text-block {
    padding: 16px;
  }

  @media (max-width: 800px) {
    background: url("../../assets/bgd1_mobi.webp"),
      url("../../assets/cover-mobile.jpg");
    height: 80vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &-title {
    line-height: 50px;

    @media (max-width: 600px) {
      font-size: 24px;
      line-height: 100%;
    }
  }

  &-subtitle {
    line-height: 24px;

    @media (min-width: 768px) {
      font-size: 24px;
    }
  }
}

.round {
  height: 70px;
  border-radius: 70%;
  transform: translateY(-40%);
  background-color: #fff;
}

.round-associate {
  height: 61px;
}
</style>
