<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1212 16.0001L24.0587 10.0626C24.3405 9.7813 24.499 9.39959 24.4993 9.00143C24.4997 8.60326 24.3418 8.22127 24.0605 7.93947C23.7792 7.65768 23.3975 7.49918 22.9994 7.49882C22.6012 7.49847 22.2192 7.6563 21.9374 7.9376L15.9999 13.8751L10.0624 7.9376C9.78062 7.65581 9.39843 7.4975 8.99992 7.4975C8.6014 7.4975 8.21921 7.65581 7.93742 7.9376C7.65562 8.21939 7.49731 8.60158 7.49731 9.0001C7.49731 9.39861 7.65562 9.78081 7.93742 10.0626L13.8749 16.0001L7.93742 21.9376C7.65562 22.2194 7.49731 22.6016 7.49731 23.0001C7.49731 23.3986 7.65562 23.7808 7.93742 24.0626C8.21921 24.3444 8.6014 24.5027 8.99992 24.5027C9.39843 24.5027 9.78062 24.3444 10.0624 24.0626L15.9999 18.1251L21.9374 24.0626C22.2192 24.3444 22.6014 24.5027 22.9999 24.5027C23.3984 24.5027 23.7806 24.3444 24.0624 24.0626C24.3442 23.7808 24.5025 23.3986 24.5025 23.0001C24.5025 22.6016 24.3442 22.2194 24.0624 21.9376L18.1212 16.0001Z"
      fill="black"
    />
  </svg>
</template>