import { createI18n } from "vue-i18n";
import fr from "../locales/fr.json";
import en from "../locales/en.json";

const i18n = createI18n({
  locale:
    navigator.language.split("-")[0] || process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: {
    en,
    fr
  } as any
});

export default i18n;
