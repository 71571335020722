import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_hero = _resolveComponent("hero")!
  const _component_product_section = _resolveComponent("product-section")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_product_display = _resolveComponent("product-display")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_Header),
      _createVNode(_component_hero),
      _createVNode(_component_product_section, { onOpenModal: _ctx.onOpenModal }, null, 8, ["onOpenModal"]),
      _createVNode(_component_Footer)
    ]),
    _createVNode(_component_modal, {
      open: _ctx.isModalOpened,
      onClosed: _ctx.closeModal
    }, {
      default: _withCtx(() => [
        (_ctx.selectedItem)
          ? (_openBlock(), _createBlock(_component_product_display, {
              key: 0,
              data: _ctx.selectedItem,
              productIndex: _ctx.selectedItemIndex
            }, null, 8, ["data", "productIndex"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["open", "onClosed"])
  ], 64))
}