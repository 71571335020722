<template>
  <svg
    width="32"
    height="32"
    viewBox="-4 -4 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
      fill="white"
      fill-opacity="0.1"
    />
    <path
      d="M19.5191 17.9617C18.9925 17.9615 18.4869 18.1677 18.1106 18.536L14.4422 16.4735C14.5176 16.1624 14.5176 15.8379 14.4422 15.5268L18.1094 13.4643C18.452 13.7975 18.902 13.998 19.3789 14.0297C19.8558 14.0614 20.3284 13.9224 20.7121 13.6375C21.0959 13.3525 21.3657 12.9403 21.4732 12.4746C21.5807 12.0089 21.519 11.5202 21.2991 11.0958C21.0792 10.6715 20.7154 10.3393 20.2729 10.1587C19.8304 9.97811 19.338 9.96091 18.884 10.1102C18.43 10.2595 18.0439 10.5655 17.7949 10.9734C17.5459 11.3814 17.4502 11.8646 17.525 12.3367L13.7712 14.4482C13.4764 14.2026 13.1176 14.046 12.737 13.9969C12.3564 13.9478 11.9697 14.0082 11.6221 14.171C11.2746 14.3338 10.9807 14.5923 10.7748 14.9161C10.5688 15.24 10.4595 15.6158 10.4595 15.9996C10.4595 16.3834 10.5688 16.7592 10.7748 17.083C10.9807 17.4069 11.2746 17.6653 11.6221 17.8281C11.9697 17.9909 12.3564 18.0513 12.737 18.0022C13.1176 17.9531 13.4764 17.7966 13.7712 17.5509L17.525 19.6636C17.4601 20.0714 17.5218 20.4893 17.7018 20.861C17.8817 21.2327 18.1714 21.5402 18.5315 21.7422C18.8917 21.9441 19.3052 22.0308 19.7161 21.9905C20.1271 21.9502 20.5159 21.7849 20.83 21.5168C21.1441 21.2488 21.3684 20.8908 21.4728 20.4913C21.5772 20.0917 21.5565 19.6698 21.4137 19.2824C21.2708 18.8949 21.0126 18.5606 20.6738 18.3244C20.3351 18.0883 19.9321 17.9617 19.5191 17.9617Z"
      fill="#222F5A"
    />
    <path
      d="M16 30.7692C7.84318 30.7692 1.23077 24.1568 1.23077 16H-1.23077C-1.23077 25.5163 6.48371 33.2308 16 33.2308V30.7692ZM30.7692 16C30.7692 24.1568 24.1568 30.7692 16 30.7692V33.2308C25.5163 33.2308 33.2308 25.5163 33.2308 16H30.7692ZM16 1.23077C24.1568 1.23077 30.7692 7.84318 30.7692 16H33.2308C33.2308 6.48371 25.5163 -1.23077 16 -1.23077V1.23077ZM16 -1.23077C6.48371 -1.23077 -1.23077 6.48371 -1.23077 16H1.23077C1.23077 7.84318 7.84318 1.23077 16 1.23077V-1.23077Z"
      fill="#222F5A"
    />
  </svg>
</template>