
import { defineComponent } from "vue";
import Home from "./views/Home.vue";

export default defineComponent({
  components: {
    Home
  }
  // metaInfo: {
  //   // if no subcomponents specify a metaInfo.title, this title will be used
  //   title: "KKiapay St Valentin",
  //   // all titles will be injected into this template
  //   titleTemplate: "%s | Gifts idea for Valentine"
  // }
});
