import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-81ef2952"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-2" }
const _hoisted_2 = { class: "flex gap-8 overflow-x-auto gallery-block" }
const _hoisted_3 = { class: "text-black font-bold font-size-32 mt-2" }
const _hoisted_4 = {
  key: 0,
  class: "my-3 text-red font-size-24 font-semibold"
}
const _hoisted_5 = {
  key: 1,
  class: "my-3 text-red font-size-24 font-semibold"
}
const _hoisted_6 = { class: "font-size-20" }
const _hoisted_7 = { class: "flex items-center gap-8 buyAndSocialBtn" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "flex items-center gap-2" }
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]
const _hoisted_12 = ["href"]
const _hoisted_13 = {
  key: 2,
  class: "mb-2 mt-3 font-size-16"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_facebook = _resolveComponent("facebook")!
  const _component_twitter = _resolveComponent("twitter")!
  const _component_whatsapp = _resolveComponent("whatsapp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.urls, (url, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          style: _normalizeStyle({ background: _ctx.generateBackgroundUrl(url) }),
          class: "image-displayer"
        }, null, 4))
      }), 128))
    ]),
    _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.item.title), 1),
    (_ctx.item.discounted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.item.discountText), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.item.amount) + " FCFA ", 1)),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", null, _toDisplayString(_ctx.item.description), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("a", {
        class: "buyBtn bg-red text-light button font-semibold my-4 px-16 font-size-18",
        href: _ctx.item.buyUrl,
        target: "_blank"
      }, _toDisplayString(_ctx.$t("buy")), 9, _hoisted_8),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("a", {
          href: _ctx.generateFacebookUrl(),
          target: "_blank",
          "data-action": "share/whatsapp/facebook"
        }, [
          _createVNode(_component_facebook)
        ], 8, _hoisted_10),
        _createElementVNode("a", {
          href: _ctx.generateTwitterUrl(),
          target: "_blank",
          "data-action": "share/whatsapp/twitter"
        }, [
          _createVNode(_component_twitter)
        ], 8, _hoisted_11),
        _createElementVNode("a", {
          href: _ctx.generateWhatsappUrl(),
          target: "_blank",
          "data-action": "share/whatsapp/share"
        }, [
          _createVNode(_component_whatsapp)
        ], 8, _hoisted_12)
      ])
    ]),
    (_ctx.item.owner)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t("productFrom")) + " " + _toDisplayString(_ctx.item.owner), 1))
      : _createCommentVNode("", true)
  ]))
}