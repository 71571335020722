
import { defineComponent, onMounted, PropType, ref } from "vue";
import { FilterItem } from "../../services/types";
import Container from "./Container.vue";

export default defineComponent({
  components: { Container },
  props: {
    data: {
      type: Array as PropType<FilterItem[]>,
      default: []
    },
    defaultValue: Object
  },
  setup(props, { emit }) {
    const current = ref(
      props.defaultValue
        ? props.defaultValue
        : props.data.length > 0
        ? props.data[0]
        : { name: "" }
    );

    const handleChange = (item: any) => {
      emit("change", item);
      current.value = item;
    };

    onMounted(() => {
      emit("change", current.value);
    });

    return {
      items: props.data,
      current,
      handleChange
    };
  }
});
