
import { defineComponent, PropType, ref } from "vue";
import { ProductItemType } from "../../services/types";
import Modal from "../utils/Modal.vue";

export default defineComponent({
  components: {
    Modal
  },
  props: {
    data: Object as PropType<ProductItemType>
  },
  setup(props) {
    return {
      item: props.data
    };
  }
});
