<template>
  <svg
    width="42"
    height="42"
    viewBox="-2 -2 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#0561AF" />
    <path
      d="M14.4613 13.9487H12.9229V16H14.4613V22.1538H17.0254V16H18.8716L19.0767 13.9487H17.0254V13.0769C17.0254 12.6154 17.128 12.4103 17.5895 12.4103H19.0767V9.84616H17.128C15.2818 9.84616 14.4613 10.6667 14.4613 12.2051V13.9487Z"
      fill="white"
    />
  </svg>
</template>
