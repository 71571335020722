import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 md:grid-cols-3 gap-10" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_item = _resolveComponent("product-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id,
        class: "pb-10 cursor-pointer",
        onClick: ($event: any) => (_ctx.onSelected(item.id))
      }, [
        _createVNode(_component_product_item, { data: item }, null, 8, ["data"])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}