<template>
  <div class="container">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.container {
  width: 80%;
  margin: auto;

  @media (max-width: 800px) {
    width: 90%;
  }
}
</style>